@import '../../global.scss';

.portfolioList{
    font-size: 16px;
    cursor: pointer;
    padding: 7px;
    border-radius: 10px;
    
    &.active{
        background-color: $main-Color;
        color: white;
    }

}