@import "../../global.scss";

.intro {
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  .left {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .imgContainer {
      width: 22rem;
      height: 23rem;
      background-color: #6e67b1;
      border-radius: 50% 50% 50% 50%;
      display: grid;
      align-items: end;
      position: absolute;
      padding-right: 30px;

      img {
        width: 82%;
        height: 97%;
        position: absolute;
        transition: all 2s ease;
        z-index: 2;
        object-fit: cover;
        left: 7%;
      }
    }
  }
  .right {
    .wrapper {
      width: 100%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        font-size: 60px;
        margin: 10px 0;
      }

      h2 {
        font-size: 35px;
      }
      h3 {
        font-size: 30px;

        span {
          font-size: inherit;
          color: $secondary-Color;
        }
      }
    }

    a {
      position: absolute;
      bottom: 10px;
      left: 70%;

      img {
        width: 30px;
        animation: arrowBlink 2s infinite;
        margin-bottom: 15px;
      }
      @keyframes arrowBlink {
        100% {
          opacity: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .intro {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    .left {

      .imgContainer {
        width: 80%;
        height: 50%;
        bottom: auto;
        margin: 20px 0;
        padding-right: 25px;
        top: 1%;

      }
    }
    .right {
        overflow: hidden;
        a {
            left: 70%;
        }
        .wrapper {
            padding-left: 23px;
            margin: 48px 0;
            h1{
              font-size: 50px;
            }
            h2{
              font-size: 25px;
            }
        }
    }
  }
}
