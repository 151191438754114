@import '../../global.scss';


.menu{
    width:300px;
    height: 100vh;
    background-color: $main-Color;
    position: fixed;
    top: 0;
    right: -300px;
    z-index: 3;
    display: grid;
    align-items: center;
    justify-items: center;
    transition: all 1s ease;
    border-radius: 50% 0 0 50%;
    

    &.active {
        right: 0;
    }
    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 30px;
        color: white;
        font-weight: 300;
        width: 60%;
    }
    li{
        margin-bottom: 20px;
        a{
            font-style: inherit;
            color: inherit;
            text-decoration: none;

            &:hover{
                font-weight: 500;
            }

        }
    }

}