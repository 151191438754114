@import "../../global.scss";

.topbar {
  background-color: white;
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  z-index: 4;
  color: $main-Color;
  transition: all 1s ease;

  .wrapper {
    margin-top: 5px;
    padding: 10px 30px;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;

    .left {
      display: grid;
      grid-auto-flow: column;
      align-items: center;

      .logo {
        font-size: 30px;
        font-weight: 700;
        text-decoration: none;
        color: inherit;
        margin-right: 40px;
      }

      .itemContainer {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        margin-left: 40px;

        .icon {
          font-size: 25px;
          margin-right: 6px;
        }

        span {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .right {
      .hamburger {
        width: 32px;
        height: 25px;
        display: grid;
        align-content: space-between;
        cursor: pointer;
        scrollbar-width: none; //this is only for firefox

        &::-webkit-scrollbar {
          //this will support the chrome ,safari and other browsers
          display: none;
        }

        span {
          width: 100%;
          height: 3px;
          background-color: $main-Color;
          transform-origin: left;
          transition: all 2s ease;
        }
      }
    }
  }
  &.active {
    background-color: $main-Color;
    color: white;

    .hamburger {
      span {
        &:first-child {
          background-color: white;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          background-color: white;
          transform: rotate(-45deg);
        }
      }
    }
  }
}

// responsive for tablet
@media screen and (max-width: 780px) {
  .topbar {
    .wrapper {
      .left {
        .logo {
          display: none;
        }
      }
    }
  }
}

// responsive for mobile

@media screen and (max-width: 480px) {
  .topbar {
    .wrapper {
      .left {
        display: grid;
        grid-auto-flow: row;
        row-gap: 5px;
        align-items: center;
        height: 57px;

        .logo {
          display: none;
        }
        .itemContainer {
          margin-left: 0px;
        }
      }
    }
  }
}
